import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, split } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import dotenv from "dotenv";
import { GC_AUTH_TOKEN } from "./../constants/settings";

dotenv.config();

export const API_URL = "https://recruitment-api.whirelab.com";
// export const API_URL = "https://recrutment-api-demo.whirelab.com";
// export const API_URL = "http://localhost:3001";

export const API_URL_2 = "wss://recruitment-api.whirelab.com";
// export const API_URL_2 = "wss://recrutment-api-demo.whirelab.com";
// export const API_URL_2 = "ws://localhost:3001";

const httpLink = createUploadLink({
  uri: API_URL,
});

const wsLink = new WebSocketLink({
  uri: API_URL_2,
  options: {
    reconnect: true,
  },
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(GC_AUTH_TOKEN);
  const locale = localStorage.getItem("lang");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
      locale: `${locale}`,
    },
  });

  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: authMiddleware.concat(link),
  cache: new InMemoryCache({ addTypename: false }),
  connectToDevTools: true,
});
