import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@iconify/vue";

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import "vuetify/dist/vuetify.min.css";
// import VueFriendlyIframe from "vue-friendly-iframe";

// Vue.use(VueFriendlyIframe);
Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: "mdi",
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: "#F7A400", // 141838
        primary_text:"#fff2f2",
        secondary: "#141838", //F7A400
        navbar: "#FFFFFF",
        error: "#b71c1c",
        // info: "#000",
      },
    },
  },
};

const vuetify = new Vuetify(opts);

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "md",
});

export default vuetify;
